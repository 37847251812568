/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;}
h2 { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem; }
h3 { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem; }

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -.08rem;
  margin: 0 0 5px 0;
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -.05rem;
}

.sidebar h6 {
  line-height: 1em;
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: #888;
  font-weight: bold;
}

.main h6 {
  font-size: 1.01em;
  padding: 0 0 6px 0;
  margin: 0;
}

p {
  margin-top: 0; }

figure {
  text-align: center;
}

figcaption {
  color: #999;
  font-size: 0.9em;
}


h3 a {
  text-decoration: none;
  color: #474a54;
}

h3 a:hover {
  text-decoration: none;
  color: #474a54;
}
a {
  color: #0096FF; text-decoration: none;
}

a:hover {
  color: #005AC8; text-decoration: underline;
}

html {
  font-size: 62.5%;
  color: #474a54;
}

body {
  font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.6;
  font-weight: 400;
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fixed-width .wrap {
  width: 900px;
  margin: 0 auto;
}

.wrap {
    position: relative;
}

.kimoby-blue{
  position:fixed;
  top:15px;
  left:72px;
}
.nav-bar {
  background: #0096FF;
  padding: 12px 0 20px;
  height: 40px;
  box-shadow: 0 0 5px #aaa;
  z-index: 1000;
  position: relative;
}

.nav-bar .right {
  position: absolute;
  right: 36px;
  top: 5px;
  width: 200px;
}

.nav-bar ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-bar .hor > li {
  padding: 0;
  margin: 0 10px;
  float: left;
  position: relative;
  height: 40px;
}

.nav-bar .hor a.current {
  font-weight: bold;
}

.nav-bar .hor > li > a {
  display: block;
  height: 100%;
  vertical-align: text-bottom;
  line-height: 3em;
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}

.logo {
  height: 100%;
  display: block;
  margin: 0 0 0 9px;
}

.logo img {
  max-height: 100%;
  position: relative;
  top: 4px;
}

.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.sidebar {
  width: 230px;
  position: absolute;
  padding: 20px 20px 0 20px;
  white-space:nowrap;
  top: 8px;
  overflow-y: auto;
  
  section {
    padding: 10px 0;
  }

  ul {
    padding: 0 0 0 10px;
    margin: 0;
    list-style: none;
  }

  h6 {
    padding: 0;
    margin: 0 0 5px 0;
  }

  a {
    color: #777;
    text-decoration: none;
    font-size: .90em;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover{
      color:#005AC8;
    }
  }

  a.active {
    color: #0096FF;
    text-decoration: none;
    font-weight: bold;
  }

}


.endpoint {
  font-size: 10px;
  padding: 0 2px;
  position: relative;
}

h3 .endpoint {
  padding: 4px 6px;
  font-size: 19px;
}

.endpoint.put {
  color: #7E57C2;
}

.endpoint.put:after {
  content: "PUT";
}

.endpoint.delete {
  color: #FF7043;
}

.endpoint.delete:after {
  content: "DELETE";
}

.endpoint.get {
  color: #66BB6A;
}
.endpoint.get:after {
  content: "GET";
}

.endpoint.post {
  color: #0096FF;
}

.endpoint.post:after {
  content: "POST";
}

.docs .main {
  border-left: #bbb 1px solid;
  margin: 0 0 0 270px;
}

hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.message-box {
  background: #F0F5FF;
  border: 3px solid #4EB9F0;
  padding: 10px;
  margin: 20px 0;
}

.message-box h5 {
  color: #4EB9F0;
  margin: 0 0 10px 0;
}

.message-box p {
  margin: 0 0 8px 0;
}

.alert {
  background: #FFEBEE;
  border-color: #F44336;
}

.alert h5 {
  color: #F44336;
}

.warning {
  background: #FFF8E1;
  border-color: #FFC107;
}

.warning h5:before {
  content: "!";
  font-weight: bold;
  color: #fff;
  background: #f0ad4e;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  position: relative;
  top: -3px;
  margin: 0 5px 0 0;

}

.warning h5 {
  color: #FFC107;
}


.doc-content {
  border-bottom: 1px solid #ddd;
  padding: 30px 0;
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 60%, #232323 60%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ffffff), color-stop(60%,#ffffff), color-stop(60%,#232323)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 60%,#232323 60%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(left,  #ffffff 0%,#ffffff 60%,#232323 60%); /* Opera 11.10+ */
background: -ms-linear-gradient(left,  #ffffff 0%,#ffffff 60%,#232323 60%); /* IE10+ */
background: linear-gradient(to right,  #ffffff 0%,#ffffff 60%,#232323 60%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#232323',GradientType=1 ); /* IE6-9 */

}

.multi-page .doc-content {
  border-bottom: 0;
}

.doc-content.full {
    background: #fff;
}

.doc-content.full .left-docs {
  width: 100%;
}

.doc-content:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.left-docs {
  width: 60%;
  float: left;
  padding: 0 50px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.right-code {
  width: 40%;
  float: left;
  padding: 0 50px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.code-viewer .languages {
  padding: 0;
  margin: 0;
  list-style: none;
}

.code-viewer .languages li {
    display: inline-block;
}

.code-viewer .languages li a {
  display: block;
  padding: 0 5px;
  z-index: 100;
}

.code-viewer a {
  text-decoration: none;
  color: #aaa;
}

.code-viewer pre {
  margin: 0 0 20px 0;
}

.code-viewer a:hover {
  color: #222;
}

.right-code .code-viewer a:hover {
  color: #fff;
}

.code-viewer a.active{
  font-weight: bold;
  color: #0096FF;
}

.return_codes tbody td:first-child {
  font-family: courier;
  font-size: .9em;
  text-align: left;
}

table {
  border: 1px solid #E1E1E1;
  margin: 0 0 20px 0;
}

th, td {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: .9em;
  text-align: left;
}

th {
  background: #f7f7f7;
}

.main .description {
  color: #aaa;
  margin-top: -11px;
}

.left-docs pre {
   border: 1px solid #aaa;
   box-shadow: 0 0 0 3px #eee;
   border-radius: 3px;
}

pre {
  white-space: pre-wrap;
  font-size: .8em;
  margin: 22px 0;
  word-wrap: break-word;
}

.parameters {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}

.parameters dt {
  width: 30%;
  float: left;
  font-weight: bold;
  text-align: right;
  position: relative;
  top: 20px;
}

.parameters dd {
  margin-left: 30%;
  padding-left: 15px;
  margin-top: 20px;
}

.parameters dd:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.search-box {
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 6px;
  width: 200px;
  background: #1976D2 url(/img/search.png) no-repeat 190px center;
  background-size: 15px 19px;
}

.search-box::-webkit-input-placeholder { /* WebKit browsers */
    color:    #ccc;
}
.search-box:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #ccc;
   opacity:  1;
}
.search-box::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #ccc;
   opacity:  1;
}
.search-box:-ms-input-placeholder { /* Internet Explorer 10+ */
   color:    #ccc;
}

.nav-bar .search-results {
  background: #fff;
  margin-top: 5px;
  border-radius: 0px;
  padding: 5px 0;
  display: none;
}

.nav-bar .search-results a {
  color: #666;
  display: block;
  padding: 10px;
}

.nav-bar .search-results a:hover {
  background: #efefef;
  text-decoration: none;
}

.nav-bar .search-results a span.description {
  display: block;
  font-size: .7em;
  color: #999;
}

#search_bg {
  width:100%;
  height:100%;
  position:fixed;
  z-index:1000;
  display:none;
  top: 0;
}

@media (max-width: 1000px) {
  .right-code {
    float: none;
    width: 100%;
    background: #232323;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .left-docs {
    float: none;
    width: 100%;
  }

  .doc-content {
    background: #fff;
    padding-bottom: 0;
  }
}

.v2-banner {
  background: #d8e76b;
  padding: 10px 20px;
  color: #888;
}