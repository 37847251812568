/*

Railscasts-like style (c) Visoft, Inc. (Damien White)

*/

.right-code .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #232323;
  color: #e6e1dc;
  -webkit-text-size-adjust: none;
}

.right-code .hljs-comment,
.right-code .hljs-javadoc,
.right-code .hljs-shebang {
  color: #bc9458;
  font-style: italic;
}

.right-code .hljs-keyword,
.right-code .ruby .hljs-function .hljs-keyword,
.right-code .hljs-request,
.right-code .hljs-status,
.right-code .nginx .hljs-title,
.right-code .method,
.right-code .hljs-list .hljs-title {
  color: #c26230;
}

.right-code .hljs-string,
.right-code .hljs-number,
.right-code .hljs-regexp,
.right-code .hljs-tag .hljs-value,
.right-code .hljs-cdata,
.right-code .hljs-filter .hljs-argument,
.right-code .hljs-attr_selector,
.right-code .apache .hljs-cbracket,
.right-code .hljs-date,
.right-code .tex .hljs-command,
.right-code .asciidoc .hljs-link_label,
.right-code .markdown .hljs-link_label {
  color: #a5c261;
}

.right-code .hljs-subst {
  color: #519f50;
}

.right-code .hljs-tag,
.right-code .hljs-tag .hljs-keyword,
.right-code .hljs-tag .hljs-title,
.right-code .hljs-doctype,
.right-code .hljs-sub .hljs-identifier,
.right-code .hljs-pi,
.right-code .input_number {
  color: #e8bf6a;
}

.right-code .hljs-identifier {
  color: #d0d0ff;
}

.right-code .hljs-class .hljs-title,
.right-code .hljs-type,
.right-code .smalltalk .hljs-class,
.right-code .hljs-javadoctag,
.right-code .hljs-yardoctag,
.right-code .hljs-phpdoc,
.right-code .hljs-dartdoc {
  text-decoration: none;
}

.right-code .hljs-constant {
  color: #da4939;
}


.right-code .hljs-symbol,
.right-code .hljs-built_in,
.right-code .ruby .hljs-symbol .hljs-string,
.right-code .ruby .hljs-symbol .hljs-identifier,
.right-code .asciidoc .hljs-link_url,
.right-code .markdown .hljs-link_url,
.right-code .hljs-attribute {
  color: #6d9cbe;
}

.right-code .asciidoc .hljs-link_url,
.right-code .markdown .hljs-link_url {
  text-decoration: underline;
}



.right-code .hljs-params,
.right-code .hljs-variable,
.right-code .clojure .hljs-attribute {
  color: #d0d0ff;
}

.right-code .css .hljs-tag,
.right-code .hljs-rules .hljs-property,
.right-code .hljs-pseudo,
.right-code .tex .hljs-special {
  color: #cda869;
}

.right-code .css .hljs-class {
  color: #9b703f;
}

.right-code .hljs-rules .hljs-keyword {
  color: #c5af75;
}

.right-code .hljs-rules .hljs-value {
  color: #cf6a4c;
}

.right-code .css .hljs-id {
  color: #8b98ab;
}

.right-code .hljs-annotation,
.right-code .apache .hljs-sqbracket,
.right-code .nginx .hljs-built_in {
  color: #9b859d;
}

.right-code .hljs-preprocessor,
.right-code .hljs-preprocessor *,
.right-code .hljs-pragma {
  color: #8996a8 !important;
}

.right-code .hljs-hexcolor,
.right-code .css .hljs-value .hljs-number {
  color: #a5c261;
}

.right-code .hljs-title,
.right-code .hljs-decorator,
.right-code .css .hljs-function {
  color: #ffc66d;
}

.right-code .diff .hljs-header,
.right-code .hljs-chunk {
  background-color: #2f33ab;
  color: #e6e1dc;
  display: inline-block;
  width: 100%;
}

.right-code .diff .hljs-change {
  background-color: #4a410d;
  color: #f8f8f8;
  display: inline-block;
  width: 100%;
}

.right-code .hljs-addition {
  background-color: #144212;
  color: #e6e1dc;
  display: inline-block;
  width: 100%;
}

.right-code .hljs-deletion {
  background-color: #600;
  color: #e6e1dc;
  display: inline-block;
  width: 100%;
}

.right-code .coffeescript .javascript,
.right-code .javascript .xml,
.right-code .tex .hljs-formula,
.right-code .xml .javascript,
.right-code .xml .vbscript,
.right-code .xml .css,
.right-code .xml .hljs-cdata {
  opacity: 0.7;
}
